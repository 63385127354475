import React, { useEffect, useState } from 'react'
import Gallery from './Gallery';


const Timeline = () => {

    const [carouselList, setCarouselList] = useState([]);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        var items = document.querySelectorAll(".timeline li");
    
        function isElementInViewport(el) {
            var rect = el.getBoundingClientRect();
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        }
    
        function callbackFunc() {
            for (var i = 0; i < items.length; i++) {
                if (isElementInViewport(items[i])) {
                    if(!items[i].classList.contains("in-view")){
                        items[i].classList.add("in-view");
                    }
                } else if(items[i].classList.contains("in-view")) {
                    items[i].classList.remove("in-view");
                }
            }
        }
        
        window.addEventListener("load", callbackFunc);
        window.addEventListener("scroll", callbackFunc);
    },[])

    const galleryUrl = "https://bbangsoon-bucket.s3.ap-northeast-2.amazonaws.com/gallery";

    let timeData = [
        {
            date : "2021.02.04",
            title : "처음 연락한 날",
            imgList: [
                galleryUrl + '/20210204/1.png',
                galleryUrl + '/20210204/2.png',
                galleryUrl + '/20210204/3.png'
            ]
        },
        {
            date : "2021.02.10",
            title : "처음 만난 날",
            imgList: [
                galleryUrl + '/20210210/1.jpg'
            ]

        },
        {
            date : "2021.02.25",
            title : "북악 스카이웨이",
            imgList: [
                galleryUrl + '/20210225/1.jpg',
                galleryUrl + '/20210225/2.jpg',
                galleryUrl + '/20210225/3.jpg',
                galleryUrl + '/20210225/4.jpg',
                galleryUrl + '/20210225/5.jpg',
                galleryUrl + '/20210225/6.jpg'
            ]

        },
        {
            date : "2021.03.20",
            title : "민지 커플이랑 만난 날",
            imgList: [
                galleryUrl + '/20210320/1.jpg',
                galleryUrl + '/20210320/2.jpg',
                galleryUrl + '/20210320/3.jpg',
                galleryUrl + '/20210320/4.jpg',
                galleryUrl + '/20210320/5.jpg',
                galleryUrl + '/20210320/6.jpg'
            ]

        },
        {
            date : "2021.03.24",
            title : "양재천 걸은 날",
            imgList: [
                galleryUrl + '/20210324/1.jpg',
                galleryUrl + '/20210324/2.jpg',
                galleryUrl + '/20210324/3.jpg',
                galleryUrl + '/20210324/4.jpg',
                galleryUrl + '/20210324/5.jpg',
                galleryUrl + '/20210324/6.jpg',
                galleryUrl + '/20210324/7.jpg',
                galleryUrl + '/20210324/8.jpg'
            ]

        },
        {
            date : "2021.04.10",
            title : "잠실 석촌호수",
            imgList: [
                galleryUrl + '/20210410/1.jpg',
                galleryUrl + '/20210410/2.jpg',
                galleryUrl + '/20210410/3.jpg',
                galleryUrl + '/20210410/4.jpg',
                galleryUrl + '/20210410/5.jpg',
                galleryUrl + '/20210410/8.jpg',
                galleryUrl + '/20210410/9.jpg',
                galleryUrl + '/20210410/10.jpg',
                galleryUrl + '/20210410/11.jpg',
                galleryUrl + '/20210410/12.jpg',
                galleryUrl + '/20210410/13.jpg',
                galleryUrl + '/20210410/14.jpg',
                galleryUrl + '/20210410/15.jpg',
                galleryUrl + '/20210410/16.jpg',
                galleryUrl + '/20210410/17.jpg'
            ]

        },
        {
            date : "2021.04.29",
            title : "와인 쇼핑",
            imgList: [
                galleryUrl + '/20210429/1.jpg',
                galleryUrl + '/20210429/2.jpg',
                galleryUrl + '/20210429/3.jpg',
                galleryUrl + '/20210429/4.jpg',
                galleryUrl + '/20210429/5.jpg',
                galleryUrl + '/20210429/6.jpg',
                galleryUrl + '/20210429/7.jpg',
                galleryUrl + '/20210429/8.jpg',
                galleryUrl + '/20210429/9.jpg',
                galleryUrl + '/20210429/10.jpg',
                galleryUrl + '/20210429/11.jpg'
            ]

        },
        {
            date : "2021.05.02",
            title : "캠핑 간 날!",
            imgList: [
                galleryUrl + '/20210502/1.jpg',
                galleryUrl + '/20210502/2.jpg',
                galleryUrl + '/20210502/3.jpg',
                galleryUrl + '/20210502/4.jpg',
                galleryUrl + '/20210502/5.jpg',
                galleryUrl + '/20210502/6.jpg',
                galleryUrl + '/20210502/7.jpg',
                galleryUrl + '/20210502/8.jpg',
                galleryUrl + '/20210502/9.jpg',
                galleryUrl + '/20210502/10.jpg',
                galleryUrl + '/20210502/11.jpg',
                galleryUrl + '/20210502/12.jpg',
                galleryUrl + '/20210502/13.jpg',
                galleryUrl + '/20210502/14.jpg',
                galleryUrl + '/20210502/15.jpg',
                galleryUrl + '/20210502/16.jpg',
                galleryUrl + '/20210502/17.jpg',
                galleryUrl + '/20210502/18.jpg',
                galleryUrl + '/20210502/19.jpg',
                galleryUrl + '/20210502/20.jpg',
                galleryUrl + '/20210502/21.jpg',
                galleryUrl + '/20210502/22.jpg',
                galleryUrl + '/20210502/23.jpg',
                galleryUrl + '/20210502/24.jpg',
                galleryUrl + '/20210502/25.jpg',
                galleryUrl + '/20210502/26.jpg',
                galleryUrl + '/20210502/27.jpg',
                galleryUrl + '/20210502/28.jpg',
                galleryUrl + '/20210502/29.jpg'
            ]

        },
    ]

    return (
        <>
            <section className="timeline">
                <ul>
                    {timeData.map(function(item) {
                        return( 
                            <li>
                                <div>
                                    <time>{item.date}</time>
                                    <div className="discovery">
                                        <h1 style={{"margin" : "0px"}}>{item.title}</h1>
                                    </div>
                                    <div className="scientist">
                                        <Gallery imgList={item.imgList} setCarouselList={setCarouselList}/>
                                    </div>
                                </div>
                            </li>
                        )
                    })}  
                </ul>
            </section>

            <div className="modal fade custom" id="exampleModal" tabindex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div id="carouselExample" className="carousel slide" data-ride="carousel">
                        <ol className="carousel-indicators">
                            {carouselList.map(function(item, index) {
                                if (index === 0){
                                    return( 
                                        <li data-target="#carouselExample" data-slide-to={String(index)} className="active"></li>
                                    )
                                } else {
                                    return( 
                                        <li data-target="#carouselExample" data-slide-to={String(index)}></li>
                                    )
                                }
                                
                            })} 
                        </ol>
                        <div className="carousel-inner">
                            {carouselList.map(function(item, index) {
                                if (index === 0){
                                    return( 
                                        <div className="carousel-item active">
                                            <img className="d-block w-100" src={item} alt="slide"/>
                                        </div>
                                    )
                                } else {
                                    return( 
                                        <div className="carousel-item">
                                            <img className="d-block w-100" src={item} alt="slide"/>
                                        </div>
                                    )
                                }
                            })} 
                        </div>
                        <a className="carousel-control-prev" href="#carouselExample" role="button" data-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#carouselExample" role="button" data-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </a>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Timeline;