import React from 'react'
import { Link } from 'react-router-dom';

const Header = () => {
    return (
        <>
            <header id="header" className="alt">
                <h1><a href="/">다욘 the 빵순</a></h1>
                <nav id="nav">
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/timeline">타임라인</Link></li>
                        <li>
                            <a href="/" className="icon solid fa-angle-down">Page Layouts</a>
                            <ul>
                                <li><a href="/ff">Left Sidebar</a></li>
                                <li><a href="/">Right Sidebar</a></li>
                                <li><a href="/">No Sidebar</a></li>
                                <li>
                                    <a href="/">Submenu</a>
                                    <ul>
                                        <li><a href="/">Option 1</a></li>
                                        <li><a href="/">Option 2</a></li>
                                        <li><a href="/">Option 3</a></li>
                                        <li><a href="/">Option 4</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li><a href="/">Elements</a></li>
                    </ul>
                </nav>
            </header>
        </>
    )
}

export default Header;