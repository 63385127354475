import React, { useEffect, useState } from 'react'
import BreadStoreCard from '../breadStore/BreadStoreCard';
import BreadReviewCard from '../breadReview/BreadReviewCard';
import axios from '../../../config/interceptors';

import Banner from './Banner';


const Home = () => {

    const [breadStoreList, setBreadStoreList] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    
        axios.get("breadstore/list/card/?page=1")
        .then(res => {
            console.log("response", res.data)
            console.log("res.data.breadStoreList", res.data.breadstoreList)
            setBreadStoreList(res.data.breadstoreList);

        })
        .catch(err => {
            console.log(err)
        });
    }, []);


    return (
        <>
            <Banner/>
            <BreadStoreCard breadStoreList={breadStoreList}/>
            <BreadReviewCard />
        </>
    )
}

export default Home;