import React from 'react'

const Banner = () => {
    return (
        <>
            <section id="banner">
                <h2>다욘의 빵 모험</h2>
                <p>이세상 모든 빵을 다 먹는 그날까지</p>
                {/* <ul className="actions special">
                <li><a href="#" className="button">Magna Sed Etiam</a></li>
                </ul> */}
            </section>
        </>
    )
}

export default Banner;