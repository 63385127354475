import React from 'react'

const Gallery = props => {

    

    function setImgForModal(){
        props.setCarouselList(props.imgList);
    }

    return (
        <>
            {props.imgList.map(function(item, index){
                return(
                    <div className="gallery" data-toggle="modal" data-target="#exampleModal" onClick={setImgForModal}>
                        <img src={item} data-target="#carouselExample" data-slide-to={String(index)} alt="img"/>
                    </div>
                )
            })}
        </>
    )
}

export default Gallery;