import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import axios from '../../../config/interceptors';

const SignUp = () => {
    const [userId, setUserId] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const [userPasswordConfirm, setUserPasswordConfirm] = useState('');

    function signUp(e) {
        e.preventDefault()
        if (!userId.trim() || !userPassword.trim() || !userPasswordConfirm.trim()){
            alert('빈 값이 있으면 안돼요~');
            e.preventDefault();
            return false;
        }

        if (userPassword !== userPasswordConfirm){
            alert('비밀번호와 비밀번호 확인이 일치하지 않아요 ㅠ');
            e.preventDefault();
            return false;
        }

        const url = '/signup/';
        const data = {
            userId: userId,
            userPassword: userPassword
        }

        const header = {
            headers: {
                'Content-type': 'application/json'
            }
        }

        axios.post(url, data, header)
        .then((res) => {
            window.location.href='/login'
        
        }).catch((err) => {
            alert("문제가 발생하였습니다.\n나중에 다시 시도해주세요");
            console.log(err);
        })
    }

    return (
        <>
            <div className="wrapper style1">
                <section id="main" className="container">
                    <section className="box">
                        <h2>회원가입</h2>
                        <form style={{"marginTop" : "20px"}}>
                            <div className="row gtr-uniform gtr-50">
                                <div className="col-12 col-12-narrower">
                                    <label style={{"marginBottom" : "8px"}}>아이디</label>
                                    <input type="text" 
                                        placeholder="아이디를 입력해주세요" 
                                        value={userId}
                                        onChange={(e) => setUserId(e.target.value)}/>
                                </div>

                                <div className="col-12 col-12-narrower">
                                    <label style={{"marginBottom" : "8px"}}>비밀번호</label>
                                    <input type="password" 
                                        placeholder="비밀번호를 입력해주세요" 
                                        value={userPassword}
                                        onChange={(e) => setUserPassword(e.target.value)}/>
                                </div>

                                <div className="col-12 col-12-narrower">
                                    <label style={{"marginBottom" : "8px"}}>비밀번호 확인</label>
                                    <input type="password" 
                                        placeholder="비밀번호 확인을 입력해주세요" 
                                        value={userPasswordConfirm}
                                        onChange={(e) => setUserPasswordConfirm(e.target.value)}/>
                                </div>

                                <div className="col-12" style={{"marginTop" : "20px"}}>
                                    <ul className="actions">
                                        <li><button onClick={(e) => signUp(e)}> 가입 </button></li>
                                        <li><Link to="/login"><button className="alt"> 취소 </button></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </form>
                    </section>
                </section>
            </div>
        </>
    )
}

export default SignUp;