import React, { useEffect, useState } from 'react'
import {BrowserRouter as Router,Route,Routes} from "react-router-dom";
import axios from '../config/interceptors';

import Footer from './common/Footer';
import Header from './common/Header';

import BreadStoreCreate from './pages/breadStore/BreadStoreCreate';

import Home from './pages/home/Home';
import TimeLine from './pages/timeline/Timeline';

import Login from './pages/account/Login';
import SignUp from './pages/account/SignUp';
import PrivateRoute from './route/PrivateRoute';


const MainPage = () => {

    const [isLogin, setIsLogin] = useState(false);

    return (
        <>
            <Router>
                <div id="page-wrapper">
                    <Header/>
                        <Routes>
                            <Route exact path='/login' element={<Login />}/>
                            <Route exact path='/signup' element={<SignUp />}/>
                            <Route path='/' element={<PrivateRoute/>}>
                                <Route exact path='/' element={<Home />}/>
                                <Route exact path='/breadstore/create' element={<BreadStoreCreate />}/>
                                <Route exact path='/timeline' element={<TimeLine />}/>
                            </Route>
                        </Routes>
                    <Footer/>
                </div>
            </Router>
        </>
    )
}

export default MainPage;