import React from 'react'
import { Link } from 'react-router-dom';

const BreadStoreCard = props => {

    console.log("props.breadStoreList", props.breadStoreList);

    return (
        <>
            <div className="wrapper style1">
                <section className="container">
                    <header className="major">
                        <h2>빵집 Wish 리스트</h2>
                        {/* <p>Aliquet ante lobortis non semper gravida. Accumsan faucibus praesent ante aliquet amet posuere ac cubilia. Eu faucibus mi neque adipiscing mi lorem. Semper blandit. Amet adipiscing interdum.</p> */}
                    </header>
                    <div className="row-scroll">
                        {props.breadStoreList? props.breadStoreList.map(function(item, index){
                            return(
                                <div className="card">
                                    <section className="box special">
                                        <h2>{item.storeName}</h2>
                                        <img src={item.storeImg} alt="store" style={{"width" : "100%", "height" : "50vw",  "objectFit" : "cover"}}/>
                                        <p>{item.storeComment}</p>
                                    </section>
                                </div>
                            )
                        }):null
                        }
                    </div>
                    <ul className="actions fit">
                        <li><Link to='/breadstore/create'><button className="button fit">빵집 추가!</button></Link></li>
                    </ul>
                </section>
            </div>
        </>
    )
}

export default BreadStoreCard;