import axios from 'axios';
import {getCookie} from "../util/cookie";


function getToken(){
  let token = getCookie("x-bbang-auth");
  if (!!token){
    return token.substring(7)
  }
  return ''
}

const instance = axios.create({
  baseURL: 'https://api.bbangsoon.com/api',
  // baseURL: 'http://localhost:8000/api',
  timeout: 3000,
  headers: {
    'x-bbang-auth' : getToken()
  }
});

instance.interceptors.request.use(
    function (response) {
      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
);

export default instance;