import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import axios from '../../../config/interceptors';
import {setCookie} from "../../../util/cookie"

const Login = () => {
    const [userId, setUserId] = useState('');
    const [userPassword, setUserPassword] = useState('');

    function login(e) {
        e.preventDefault();
        if (!userId.trim() || !userPassword.trim()){
            alert('빈 값이 있으면 안돼요~');
            e.preventDefault();
            return false;
        }

        const url = '/login/';
        const data = {
            userId: userId,
            userPassword: userPassword
        }

        const header = {
            headers: {
                'Content-type': 'application/json'
            }
        }

        axios.post(url, data, header)
        .then((res) => {
            alert("로그인 성공!");
            let token = "Bearer " + res.data.access_token;
            setCookie('x-bbang-auth',  token, {path: "/", secure: false});
            window.location.href='/';
        }).catch((err) => {
            alert("문제가 발생하였습니다.\n나중에 다시 시도해주세요");
            console.log(err);
        })
    }

    return (
        <>
            <div className="wrapper style1">
                <section id="main" className="container">
                    <section className="box">
                        <h2>로그인</h2>
                        <form style={{"marginTop" : "20px"}}>
                            <div className="row gtr-uniform gtr-50">
                                <div className="col-12 col-12-narrower">
                                    <label style={{"marginBottom" : "8px"}}>아이디</label>
                                    <input type="text" 
                                        placeholder="아이디를 입력해주세요"
                                        value={userId}
                                        onChange={(e) => setUserId(e.target.value)}
                                    />
                                </div>

                                <div className="col-12 col-12-narrower">
                                    <label style={{"marginBottom" : "8px"}}>비밀번호</label>
                                    <input type="password" 
                                        placeholder="비밀번호를 입력해주세요" 
                                        value={userPassword}
                                        onChange={(e) => setUserPassword(e.target.value)}
                                    />
                                </div>

                                <div className="col-12" style={{"marginTop" : "20px"}}>
                                    <ul className="actions">
                                        <li><button onClick={(e) => login(e)}> 로그인 </button></li>
                                        <li><Link to="/signup"> <button className="alt"> 회원가입 </button></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </form>
                    </section>
                </section>
            </div>
        </>
    )
}

export default Login;