import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import axios from '../../../config/interceptors';

const BreadStoreCreate = () => {

    const [storeName, setStoreName] = useState('');
    const [storeImgFile, setStoreImgFile] = useState('');
    const [storePriority, setStorePriority] = useState('3');
    const [storeComment, setStoreComment] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    function uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
        });
    }

    function getFileExtension(filename){
        return filename.substring(filename.lastIndexOf('.')+1, filename.length) || filename;
    }

    function renameFile(file, newFileName){
        return new File([file], newFileName, {type: file.type});
    }

    function uploadFile(e){
        let selectedFile = e.target.files[0];
        let fileExtension = getFileExtension(selectedFile.name);
        let newFileName = uuidv4() + '.' + fileExtension;
        const file = renameFile(selectedFile, newFileName);

        const data = {
            filename: newFileName
        }

        const header = {
            headers: {
                'Content-type': 'application/json'
            }
        }
    
        axios.post("/presignedurl/storepic/", data, header)
        .then(res => {
            if (res.data) {
                const url = res.data.url;
                const header = {
                    headers: {
                        'Content-type': file.type
                    }
                }
                axios.put(url, file, header)
                .then(res => {
                    setStoreImgFile('https://bbangsoon-bucket.s3.ap-northeast-2.amazonaws.com/static/media/' + newFileName);
                })
                .catch(err => {
                    console.log(err)
                    alert("이미지 업로드 중 일시적인 문제가 발생했습니다.\n나중에 다시 시도해주세요")
                });

            }
        })
        .catch(err => {
            console.log(err)
            alert("이미지 url 생성중 일시적인 문제가 발생했습니다.\n나중에 다시 시도해주세요")
        });
    }

    function saveBreadStore(e) {
        e.preventDefault();

        const data = {
            storeName: storeName,
            priority: storePriority,
            storeImg: storeImgFile,
            storeComment: storeComment
        }

        const header = {
            headers: {
                'Content-type': 'application/json'
            }
        }
    
        axios.post("breadstore/create/", data, header)
        .then(res => {
           alert('저장되었습니다');
           window.location.href='/';
        })
        .catch(err => {
            console.log(err)
            alert("저장 중 일시적인 문제가 발생했습니다.\n나중에 다시 시도해주세요")
        });

    }

    return (
        <>
            <div className="wrapper style1">
                <section id="main" className="container">
                    <section className="box">
                        <h2>빵집 등록</h2>
                        <form>
                            <div className="row gtr-uniform gtr-50">
                                <div className="col-12 col-12-narrower">
                                    <label>빵집명</label>
                                    <input type="text" 
                                        placeholder="빵집명을 입력해주세요" 
                                        value={storeName}
                                        onChange={(e) => setStoreName(e.target.value)}/>
                                </div>

                                <div className="col-12 col-12-narrower">
                                    <label>빵집사진</label>
                                    <input 
                                        type="file"
                                        accept=".png, .jpg"
                                        onChange={(e) => uploadFile(e)}
                                    />
                                </div>
    
                                <div className="col-12 col-12-narrower">
                                    <label>방문우선순위</label>
                                </div>
                                <div className="col-4 col-12-narrower">
                                    <input 
                                        type="radio" 
                                        id="priority-high" 
                                        name="priority"
                                        value="3"
                                        onClick={(e) => setStorePriority(e.target.value)}
                                        checked={storePriority === "3"}
                                    />
                                    <label for="priority-high">상</label>
                                </div>
                                <div className="col-4 col-12-narrower">
                                    <input 
                                        type="radio" 
                                        id="priority-normal" 
                                        name="priority"
                                        value="2"
                                        onClick={(e) => setStorePriority(e.target.value)}
                                        checked={storePriority === "2"}
                                    />
                                    <label for="priority-normal">중</label>
                                </div>
                                <div className="col-4 col-12-narrower">
                                    <input 
                                        type="radio" 
                                        id="priority-low"
                                        name="priority"
                                        value="1"
                                        onClick={(e) => setStorePriority(e.target.value)}
                                        checked={storePriority === "1"}
                                    />
                                    <label for="priority-low">하</label>
                                </div>

                                <div className="col-12">
                                <label>먹고싶은 빵</label>
                                    <textarea placeholder="먹고싶은 빵을 적어주세요 :)" 
                                            rows="6" 
                                            value={storeComment}
                                            onChange={(e) => setStoreComment(e.target.value)}/>
                                </div>
                                
                                <div className="col-12">
                                    <ul className="actions">
                                        <li><button onClick={saveBreadStore}> 등록 </button></li>
                                        <li><Link to='/'><button className="alt">취소</button></Link></li>
                                    </ul>
                                </div>

                            </div>
                        </form>
                    </section>
                </section>
            </div>
        </>
    )
}

export default BreadStoreCreate;