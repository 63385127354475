import React from 'react'

const Footer = () => {
    return (
        <>
            <footer id="footer">
                <ul className="menu">
                    <li><a href="/">About</a></li>
                    <li><a href="/">Terms of Use</a></li>
                    <li><a href="/">Privacy Policy</a></li>
                    <li><a href="/">Contact Us</a></li>
                </ul>
                <div className="copyright">
                    &copy; JaeHyon. All rights reserved.
                </div>
            </footer>
        </>
    )
}

export default Footer;