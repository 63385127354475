import React from 'react'

const BreadReviewCard = () => {
    return (
        <>
           <div className="wrapper style2">
                <section className="container medium">
                <header className="major">
                    <h2>Consectetuer Adipis</h2>
                    <p>Aliquet ante lobortis non semper gravida. Accumsan faucibus praesent ante aliquet amet posuere ac cubilia. Eu faucibus mi neque adipiscing mi lorem. Semper blandit. Amet adipiscing interdum.</p>
                </header>
                <div className="row gtr-uniform">
                    <div className="col-6 col-12-narrower">
                    <section className="box special">
                        <i className="icon major alt solid fa-chart-bar"></i>
                        <h3>Ipsum Feugiat</h3>
                        <p>Aliquet ante lobortis semper gravida. quet amet posuere ac cubilia. Eu faucibus mi neque adipiscing mi lorem ipsum dolor sit amet nullam.
                        Amet semper interdum nunc aliquam lobortis id lobortis.</p>
                    </section>
                    </div>
                    <div className="col-6 col-12-narrower">
                    <section className="box special">
                        <i className="icon major alt solid fa-cog"></i>
                        <h3>Lorem Tempus</h3>
                        <p>Aliquet ante lobortis semper gravida. quet amet posuere ac cubilia. Eu faucibus mi neque adipiscing mi lorem ipsum dolor sit amet nullam.
                        Amet semper interdum nunc aliquam lobortis id lobortis.</p>
                    </section>
                    </div>
                    <div className="col-6 col-12-narrower">
                    <section className="box special">
                        <i className="icon major alt solid fa-desktop"></i>
                        <h3>Gravida Adipiscing</h3>
                        <p>Aliquet ante lobortis semper gravida. quet amet posuere ac cubilia. Eu faucibus mi neque adipiscing mi lorem ipsum dolor sit amet nullam.
                        Amet semper interdum nunc aliquam lobortis id lobortis.</p>
                    </section>
                    </div>
                    <div className="col-6 col-12-narrower">
                    <section className="box special">
                        <i className="icon major alt solid fa-check"></i>
                        <h3>Ligula Magna</h3>
                        <p>Aliquet ante lobortis semper gravida. quet amet posuere ac cubilia. Eu faucibus mi neque adipiscing mi lorem ipsum dolor sit amet nullam.
                        Amet semper interdum nunc aliquam lobortis id lobortis.</p>
                    </section>
                    </div>
                </div>
                </section>
            </div>
        </>
    )
}

export default BreadReviewCard;